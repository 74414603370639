import i18n from 'i18next';
import { Graphics } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import {
  setBetAmount,
  setBrokenGame,
  setCoinValue,
  setCurrentBonus,
  setFreeRoundsBonus,
  setReplayBet,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { getBetAmountGql, getUserGql, getWinAmountGql } from '../../gql/query';
import {
  calcBottomContainerHeight,
  countCoins,
  formatNumber,
  isMobilePortrait,
  normalizeBalance,
  normalizeCoins,
  showCurrency,
} from '../../utils';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { bottomContainerTextStyle, eventManager } from '../config';
import { ControlButton } from '../controlButtons';

import InfoBtn from './infoBtn';

class BottomContainer extends ViewContainer {
  private isInitialized = false;

  private rect: Graphics;

  private infoBtn: ControlButton;

  private balance: TextField;

  private freeRoundsAmount: TextField;

  private win: TextField;

  private bet: TextField;

  // TODO have to be refactored
  private currency = 'FUN';

  private maxFontHeight: number;

  private maxFontWidth: number;

  constructor() {
    super();

    this.rect = new Graphics();
    this.addChild(this.rect);
    this.infoBtn = new InfoBtn();
    this.addChild(this.infoBtn);
    this.maxFontHeight = 30;
    this.maxFontWidth = 300;
    this.balance = new TextField(this.createText('balance', 0), 270, 100, bottomContainerTextStyle);
    this.balance.text.anchor.set(0, 0.5);
    this.balance.text.visible = true;
    this.freeRoundsAmount = new TextField(
      this.createText(
        'freeRounds',
        setFreeRoundsBonus().isActive ? setFreeRoundsBonus().rounds : setCurrentBonus().rounds,
      ),
      270,
      100,
      bottomContainerTextStyle,
    );
    this.freeRoundsAmount.text.anchor.set(0, 0.5);
    this.freeRoundsAmount.text.visible = false;
    this.addChild(this.freeRoundsAmount.getText());
    if (!setReplayBet()) {
      this.addChild(this.balance.getText());
    }
    this.win = new TextField(
      this.createText(
        'win',
        formatNumber(
          this.currency,
          normalizeCoins(
            countCoins({
              totalAmount: setWinAmount(),
            }),
          ),
          true,
        ),
      ),
      250,
      100,
      bottomContainerTextStyle,
    );
    this.zIndex = 2;
    this.win.text.visible = !setBrokenGame();
    this.win.text.anchor.set(0.5, 0.5);
    this.addChild(this.win.getText());

    this.bet = new TextField(
      this.createText(
        'bet',
        formatNumber(this.currency, normalizeCoins(countCoins({ totalAmount: setBetAmount() })), true),
      ),
      250,
      100,
      bottomContainerTextStyle,
    );
    this.bet.text.anchor.set(1, 0.5);
    this.addChild(this.bet.getText());

    eventManager.addListener(EventTypes.HIDE_WIN_LABEL, this.setWinLabelVisible.bind(this, false));
    eventManager.addListener(EventTypes.SHOW_WIN_LABEL, this.setWinLabelVisible.bind(this, true));
    eventManager.addListener(EventTypes.UPDATE_TOTAL_WIN_VALUE, this.updateTotalWinValue.bind(this));
    eventManager.addListener(EventTypes.UPDATE_WIN_VALUE, this.updateWinValue.bind(this));
    eventManager.addListener(EventTypes.UPDATE_USER_BALANCE, this.updateUserBalance.bind(this));
    eventManager.addListener(EventTypes.UPDATE_FREE_ROUNDS_LEFT, this.updateFreeRoundsAmount.bind(this));
    eventManager.addListener(EventTypes.UPDATE_BET, () => this.updateBetAmount(setBetAmount()));
    this.getUserBalance();
    this.getBetAmount();
    this.getWinAmount();
  }

  private getBetAmount(): void {
    client
      .watchQuery<{ betAmount: number }>({ query: getBetAmountGql })
      .subscribe(({ data }) => {
        this.updateBetAmount(data.betAmount);
      });
  }

  private getWinAmount(): void {
    client
      .watchQuery<{ winAmount: number }>({ query: getWinAmountGql })
      .subscribe(({ data }) => {
        this.updateWinAmount(data.winAmount);
      });
  }

  private getUserBalance(): void {
    client
      .query({
        query: getUserGql,
      })
      .then((res) => {
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, res.data.user.balance);
      });
  }

  private async updateUserBalance(balance: { currency: string; amount: number }): Promise<void> {
    if (!this.isInitialized) {
      this.currency = balance.currency;
      this.updateBetAmount(setBetAmount());
      this.updateWinAmount(setWinAmount());

      this.isInitialized = true;
    }
    this.updateBalance(balance.amount);
  }

  private createText(key: 'balance' | 'win' | 'bet' | 'totalWin' | 'freeRounds', value: number | string): string {
    return `${i18n.t(key).toUpperCase()}: ${value}`;
  }

  private setWinLabelVisible(visible: boolean): void {
    this.win.text.visible = visible;
  }

  private updateWinValue(newValue: number): void {
    if (setUserLastBetResult().coinValue === setCoinValue()) {
      this.setWinLabelVisible(true);
      this.win.setText(`${this.createText('win', newValue)}`);
    }
  }

  private updateTotalWinValue(newValue: number): void {
    this.setWinLabelVisible(true);
    this.win.setText(
      `${this.createText(
        'totalWin',
        formatNumber(
          this.currency,
          normalizeCoins(newValue, setUserLastBetResult().coinValue),
          showCurrency(this.currency),
        ),
      )}`,
    );
  }

  protected resize(width: number, height: number): void {
    const containerHeight = calcBottomContainerHeight(width, height);
    const mobilePortrait = isMobilePortrait(width, height);

    this.rect.clear();
    this.rect.beginFill(0x663511, 0.46);
    this.rect.drawRect(0, 0, width, containerHeight);
    this.rect.endFill();

    const infoBtnHeight = height * (mobilePortrait ? 0.025 : 0.04);
    const padding = width * 0.02;
    this.infoBtn.height = infoBtnHeight;
    this.infoBtn.width = infoBtnHeight;
    this.infoBtn.x = padding + this.infoBtn.width / 2;

    const infoBtnOffset = this.infoBtn.width + 15;

    this.maxFontHeight = mobilePortrait ? containerHeight * 0.6 : containerHeight * 0.8;
    this.maxFontWidth = mobilePortrait ? (width - padding * 3) / 2 : (width - padding * 3 - infoBtnOffset) / 3;

    this.balance.update(this.maxFontWidth, this.maxFontHeight);
    this.win.update(this.maxFontWidth, this.maxFontHeight);
    this.bet.update(this.maxFontWidth, this.maxFontHeight);
    this.freeRoundsAmount.update(this.maxFontWidth, this.maxFontHeight);

    if (mobilePortrait) {
      this.infoBtn.y = containerHeight / 4;
      this.balance.text.position.set(padding, containerHeight * (3 / 4));
      this.win.text.position.set(width / 2, containerHeight / 4);
      this.bet.text.position.set(width - padding, containerHeight * (3 / 4));
      this.freeRoundsAmount.text.position.set(padding, containerHeight * (3 / 4));
    } else {
      this.infoBtn.y = containerHeight / 2;
      this.balance.text.position.set(padding + infoBtnOffset, containerHeight / 2);
      this.win.text.position.set((width + infoBtnOffset) / 2, containerHeight / 2);
      this.bet.text.position.set(width - padding, containerHeight / 2);
      this.freeRoundsAmount.text.position.set(padding + infoBtnOffset, containerHeight / 2);
    }

    this.pivot.set(0, containerHeight);
    this.position.set(0, height);
  }

  private updateFreeRoundsAmount(amount: number): void {
    this.freeRoundsAmount.setText(this.createText('freeRounds', amount));
    this.freeRoundsAmount.update(this.maxFontWidth, this.maxFontHeight);
  }

  private updateBalance(amount: number): void {
    this.balance.setText(
      this.createText('balance', formatNumber(this.currency, normalizeBalance(amount), showCurrency(this.currency))),
    );
    this.balance.update(this.maxFontWidth, this.maxFontHeight);
  }

  private updateWinAmount(amount: number): void {
    if (this.freeRoundsAmount.text.visible) return;
    this.win.setText(
      this.createText(
        'win',
        formatNumber(
          this.currency,
          normalizeCoins(countCoins({ totalAmount: amount }), setUserLastBetResult().coinValue),
          showCurrency(this.currency),
        ),
      ),
    );
    this.win.update(this.maxFontWidth, this.maxFontHeight);
  }

  private updateBetAmount(amount: number): void {
    this.bet.setText(
      this.createText(
        'bet',
        formatNumber(this.currency, normalizeCoins(countCoins({ totalAmount: amount })), showCurrency(this.currency)),
      ),
    );
    this.bet.update(this.maxFontWidth, this.maxFontHeight);
  }

  protected onModeChange(settings: { mode: GameMode }): void {
    if (settings.mode === GameMode.FREE_ROUND_BONUS || setFreeRoundsBonus().isActive) {
      this.freeRoundsAmount.text.visible = true;
      this.balance.text.visible = false;
    } else {
      this.freeRoundsAmount.text.visible = false;
      this.balance.text.visible = true;
    }
  }
}

export default BottomContainer;
